import React, { useEffect } from 'react';
import { Modal, Form, message } from 'antd';
import TypeForm from '../forms/TypeForm';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { Type } from '@/interfaces/type.interface';
// Services
import APIService from '@/services/API';

export interface TypesModalProps {
  visible: boolean;
  edit?: boolean;
  data?: Record<string, string | number>;
  onCancel?: () => void;
  onOk?: () => void;
}

const TypesModal: React.FC<TypesModalProps> = ({
  visible,
  edit,
  onCancel,
  onOk,
  data,
}) => {
  const [formRef] = Form.useForm<Type>();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (edit && data) {
      formRef.setFieldsValue(data);
    }
  }, [edit, data, formRef]);

  const handleOnSubmit = async (values: Type) => {
    setLoading(true);
    const response = data?._id
      ? await APIService.updateType(data?._id as string, values)
      : await APIService.createType(values);
    if (response.ok) {
      if (onOk) {
        onOk();
      }
      formRef.resetFields();
      if (edit) {
        message.success(t('types.updateSuccess'));
      } else {
        message.success(t('types.createSuccess'));
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      open={visible}
      title={edit ? t('types.edit') : t('types.create')}
      onOk={() => formRef.submit()}
      okText={edit ? t('general.update') : t('general.create')}
      width={700}
      onCancel={() => {
        onCancel?.();
        formRef.resetFields();
      }}
      okButtonProps={{ loading }}
    >
      <TypeForm formRef={formRef} onSubmit={handleOnSubmit} />
    </Modal>
  );
};

export default TypesModal;
