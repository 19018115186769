import React from 'react';
import { Form, FormInstance, Input, Select, Row, Col, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { Type } from '@/interfaces/type.interface';

export interface TypeFormProps {
  formRef: FormInstance<Type>;
  onSubmit?: (values: Type) => Promise<void>;
}

const TypeForm: React.FC<TypeFormProps> = ({ onSubmit, formRef }) => {
  const { t, i18n } = useTranslation();

  return (
    <Form
      form={formRef}
      name="type-form"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
      initialValues={{
        slug: {
          en: '',
          es: '',
        },
        name: {
          en: '',
          es: '',
        },
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={24} sm={12} md={10}>
          <Tabs
            size="small"
            tabPosition="left"
            defaultActiveKey={i18n.language}
            items={[
              {
                key: 'es',
                label: 'ES',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('types.name')}
                    name={['name', 'es']}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        whitespace: true,
                        message: <>{t('users.required')}</>,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
              {
                key: 'en',
                label: 'EN',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('types.name')}
                    name={['name', 'en']}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        whitespace: true,
                        message: <>{t('users.required')}</>,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
            ]}
          />
        </Col>
        <Col span={24} sm={12} md={6}>
          <Tabs
            size="small"
            tabPosition="left"
            defaultActiveKey={i18n.language}
            items={[
              {
                key: 'es',
                label: 'ES',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('types.slug')}
                    name={['slug', 'es']}
                    rules={[
                      {
                        max: 30,
                        min: 4,
                        required: true,
                        type: 'string',
                        whitespace: true,
                        message: <>{t('users.required')}</>,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
              {
                key: 'en',
                label: 'EN',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('types.slug')}
                    name={['slug', 'en']}
                    rules={[
                      {
                        max: 30,
                        min: 4,
                        required: true,
                        type: 'string',
                        whitespace: true,
                        message: <>{t('users.required')}</>,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
            ]}
          />
        </Col>

        <Col span={24} md={8}>
          <Form.Item
            label={t('general.status')}
            name="status"
            initialValue={1}
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('general.active'),
                  value: 1,
                },
                {
                  label: t('general.inactive'),
                  value: 0,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TypeForm;
