import { Button, Table, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { parseFilter } from '@/utils/filter';
import { Type, RawType } from '@/interfaces/type.interface';
import { FilterByStatus } from '../utils/CommonFilters';

export interface TypesTableProps {
  data?: RawType[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onEdit?: (data: Type) => void;
  onView?: (data: Type) => void;
  onDelete?: (id: string) => void;
}

const TypesTable = ({
  data,
  total,
  loading,
  onFilter,
  onDelete,
  onEdit,
}: TypesTableProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const Columns: ColumnsType<RawType> = [
    {
      title: t('types.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_id, row) => (language === 'es' ? row.name?.es || '' : row.name?.en || ''),
    },
    {
      title: t('types.slug'),
      dataIndex: 'slug',
      key: 'slug',
      render: (_id, row) => (language === 'es' ? row.name?.es || '' : row.name?.en || ''),
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) =>
        created_at && new Date(created_at).toLocaleDateString(),
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      filters: [
        {
          text: t('general.active'),
          value: '1',
        },
        {
          text: t('general.inactive'),
          value: '0',
        },
      ],
      filterDropdown: FilterByStatus([
        {
          label: t('general.active'),
          value: '1',
        },
        {
          label: t('general.inactive'),
          value: '0',
        },
      ]),
      key: 'status',
      render: (status: number) =>
        status === 1 ? t('general.active') : t('general.inactive'),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (_: string, record: RawType) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (
    pagination: any = {},
    filters: any = {},
    sorter: any,
    { action }: { action: string },
  ) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <>
      <Table
        className="custom-table"
        dataSource={data}
        columns={Columns}
        loading={loading}
        onChange={handleOnChange}
        rowKey={(e) => e._id}
        pagination={{
          total,
          defaultPageSize: 10,
          showTotal: (total) => t('general.total', { total }),
        }}
      />
    </>
  );
};

export default TypesTable;
